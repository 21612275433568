<template>
  <div class="main-data">
    <mcb-tool-bar :text="pageTitle" />

    <!-- <div class="sub-tools"> -->
      <!-- <el-input :value="filterPhone" @input="inputPhone" placeholder="Поиск по номеру телефона" clearable /> -->
      <!-- <el-date-picker v-model="filterDate" type="daterange" start-placeholder="начало" end-placeholder="окончание" value-format="yyyy-MM-dd" format="dd MMM yyyy" /> -->
      <!-- <el-select v-model="filterStatus" placeholder="Выберите статус" clearable> -->
        <!-- <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" /> -->
      <!-- </el-select> -->
    <!-- </div> -->
    <table class="sub-tools">
      <tr>
        <td ref="tool0">
          <el-input :value="filterPhone" @input="inputPhone" placeholder="Поиск по номеру телефона" clearable />
        </td>
        <td ref="tool1">
          <el-date-picker v-model="filterDate" type="daterange" start-placeholder="начало" end-placeholder="окончание" value-format="yyyy-MM-dd" format="dd MMM yyyy" />
        </td>
        <td ref="tool2">
          <el-select v-model="filterStatus" placeholder="Выберите статус" clearable>
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </td>
      </tr>
    </table>

    <el-table
      ref="table"
      :data="tableData"
      :default-sort="sortObj(currentSort)"
      empty-text="Нет данных"
      @sort-change="val => currentSort=sortStr(val)"
      style="width: 100%">

      <el-table-column prop="patient_phone" label="Номер телефона" sortable="custom">
        <template slot-scope="scope">
          <router-link :to="{ name: mode, params: { recId: scope.row.id }, query: $route.query }">{{scope.row.patient_phone}}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="created_at_iso" label="Дата загрузки" sortable="custom">
        <template slot-scope="scope">
          {{mkDate(scope.row.created_at_iso)}}
        </template>
      </el-table-column>

      <el-table-column prop="status" label="Статус">
        <template slot-scope="scope">
          <span :class="'status-'+scope.row.status"> {{statusNames[scope.row.status]}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      hide-on-single-page
      :total="totalRecords"
      :current-page="currentPage" 
      @current-change="val => currentPage=val" />

    <component :is=mode v-model="editRecord" @cancel="cancelHandle" v-if="editRecord !== null" />
  </div>
</template>

<script>
import fecha from 'fecha'
import { statusNames, statusOptions } from '@/components/utils.js'
import MultiPage from '@/mixins/multi-page.js'

const ISO_FORMAT_TIME = 'YYYY-MM-DDTHH:mm:ss'
const RU_FORMAT_DATE = 'DD MMMM YYYY'

export default {
  mixins: [MultiPage],

  props: {
    mode: {
      type: String
    }
  },

  components: { 
    Fio: () => import('./Fio.vue'),
    Prescription: () => import('./Prescription.vue'),
    Control: () => import('./Control.vue')
  },

  watch: {
    filterPhone: {
      handler: 'loadTableData'
    },

    filterDate: {
      handler: 'loadTableData'
    },

    filterStatus: {
      handler: 'loadTableData'
    },

    tableData: {
      handler: 'toolResize'
    },
  },

  mounted () {
    this.toolResize()
  },

  data () {
    return {
      statusNames: statusNames,
      statusOptions: statusOptions,
    }
  },

  computed: {
    pageTitle () {
      return {
        fio: 'ФИО',
        prescription: 'Назначения',
        control: 'Контроль',
      }[this.mode]
    },

    filterPhone: {
      get: function () {
        return this.$route.query.phone || ''
      },
      set: function (val) {
        this.part_route({ phone: val || null, page: null })
      }
    },

    filterDate: {
      get: function () {
        const val = (this.$route.query.period || '').split('=')
        return [(val[0] || ''), (val[1] || '')]
      },
      set: function (val) {
        if (val && val[0]) {
          val = [val[0], (val[1] || '')].join('=')
        } else {
          val = null
        }
        this.part_route({ period: val || null, page: null })
      }
    },

    filterStatus: {
      get: function () {
        const parsed = parseInt(this.$route.query.status, 10)
        if (isNaN(parsed)) { return null }
        return parsed
      },
      set: function (val) {
        this.part_route({ status: val.toString() || null, page: null })
      }
    }
  },

  methods: {
    inputPhone (val) {
      // eslint-disable-next-line no-useless-escape
      val = (val || '').toString().replace(/[^\+\d+]/g, '')
      if (val) {
        val = val.substring(0, 12)  
      }
      this.filterPhone = val
    },

    toolResize () {
      const el0 = this.$refs.table.$el.querySelectorAll('table.el-table__header th')
      this.$refs.tool0.style.width = el0[0].clientWidth+'px' 
      this.$refs.tool1.style.width = el0[1].clientWidth+'px' 
      this.$refs.tool2.style.width = el0[2].clientWidth+'px' 
    },

    loadTableData () {
      this.$wait.show()
      const dt = {
        page: {
          current: this.currentPage,
          size: this.pageSize
        },
        sort: this.currentSort,
        filter_phone: this.filterPhone,
        filter_date: this.filterDate,
        filter_status: this.filterStatus
      }
      this.$axios.post('/main-data', dt)
        .then(response => {
          const { data, total } = response.data
          this.tableData = data || []
          this.totalRecords = total || 0
        })
        .catch(err => {
          if (err.response && err.response.data.error) {
            this.$notify.error({title: 'Error',  message: err.response.data.error})
          }
        })
        .finally(() => { this.$wait.hide() })
    },

    mkDate (dt) {
      return dt ? fecha.format(fecha.parse(dt, ISO_FORMAT_TIME), RU_FORMAT_DATE) : ''
    },

    saveHandle (val) {
      if (val) {
        for (var i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].id === val.id) {
            this.$set(this.tableData, i, val)
            break
          }
        }
        this.cancelHandle()
      }
    },

    cancelHandle () {
      this.$router.push({ name: this.mode + 's', query: this.$route.query })
    },
  }
}
</script>

<style scoped lang="less" >
@import "../style.less";

.main-data {
  .sub-tools {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // justify-content: flex-start;
    width: 100%;

    .el-input {
      max-width: 300px;
    }
  }
}
</style>
